<template>
    <div class="error">
        <img class="err_404" :src="imgUrl" alt="404">
        <p class="err_msg">{{ ErrMsg }}</p>
    </div>
</template>

<script>
export default {
    props: {
        // 警示语
        ErrMsg: {
            type: String,
            default: '抱歉!出现错误了'
        }
    },

    data () {
        return {
            // 404 图片
            imgUrl: require('@/assets/404.png')
        }
    }
}
</script>

<style lang="scss" scoped>
.error {
    padding-top: 300px;
    text-align: center;

    .err_404 {
        display: block;
        margin: 0 auto;
        width: 474px;
    }

    .err_msg {
        line-height: 90px;
        font-size: 28px;
        font-weight: bold;
        color: #bdbdbd;
    }
}
</style>
